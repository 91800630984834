import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { MenuController, Platform, ToastController, AlertController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';
import { Authentication } from './providers/authenticating.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  appPages = [
    {
      title: 'Dashboard',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Products',
      url: '/product/tab/products',
      icon: 'shirt'
    },
    {
      title: 'Sales & Purchase',
      url: '/app/tabs/daily-sales',
      icon: 'bar-chart'
    },
    {
      title: 'Sellers',
      url: '/sellers/tab/seller',
      icon: 'people'
    },
    {
      title: 'Customers',
      url: '/customer/tab/customers',
      icon: 'people'
    },
    {
      title: 'Tools',
      url: '/tools/tab/amazon',
      icon: 'hammer'
    },
    {
      title: 'Setting',
      url: '/setting',
      icon: 'cog'
    }
  ];
  loggedIn = false;
  dark = false;
  innerWidth: number;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private authenticationService: Authentication,
    private location: Location,
    public alertController: AlertController
  ) {
    this.onResize();
    this.storage.get('darkMode').then(res => {
      this.dark = res;
    });
    this.initializeApp();
  }

  async ngOnInit() {
    this.storage.get('darkMode').then(res => {
      this.dark = res;
    });
    this.checkLoginStatus();
    this.listenForLoginEvents();

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.dark) {
        this.statusBar.backgroundColorByHexString('#222428');
      } else {
        this.statusBar.backgroundColorByName('white');
      }
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      console.log('Back press handler!');
      if (this.location.isCurrentPathEqualTo('/home')) {

        // Show Exit Alert!
        console.log('Show Exit Alert!');
        this.showExitConfirm();
        processNextHandler();
      } else {

        // Navigate to back page
        console.log('Navigate to back page');
        this.location.back();

      }

    });

    this.platform.backButton.subscribeWithPriority(5, () => {
      console.log('Handler called to force close!');
      this.alertController.getTop().then(r => {
        if (r) {
          // tslint:disable-next-line:no-string-literal
          navigator['app'].exitApp();
        }
      }).catch(e => {
        console.log(e);
      });
    });

  }

  showExitConfirm() {
    this.alertController.create({
      header: 'Exit app?',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          // tslint:disable-next-line:no-string-literal
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }


  onSelectingDarkMode() {
    this.storage.set('darkMode', this.dark);
  }

  checkLoginStatus() {
    this.authenticationService.authState.subscribe(state => {
      if (state) {
        this.loggedIn = true;
        this.router.navigate(['home']);
      } else {
        this.loggedIn = false;
        this.router.navigate(['login']);
      }
    });
    // return this.userData.isLoggedIn().then(loggedIn => {
    //   return this.updateLoggedInStatus(loggedIn);
    // });
    // return this.authenticationService.authState.subscribe(state => {
    //   return this.updateLoggedInStatus(state);
    // });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
    // if (loggedIn) {
    //   this.loggedIn = true;
    //   this.router.navigate(['home']);
    // } else {
    //   this.loggedIn = false;
    //   this.router.navigate(['login']);
    // }
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.authenticationService.logout();
    // this.userData.logout().then(() => {
    //   return this.router.navigateByUrl('/home');
    // });
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
