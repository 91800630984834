import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as url from '../constants/url';
import { Product } from '../interfaces/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    public storage: Storage,
    private http: HttpClient
  ) { }

  // api's for all product db related requests
  addProduct(product: Product): Observable<any> {
    return this.http.post(url.baseUrl + 'products?code=' + url.key, product);
  }

  getAllProducts(): Observable<any> {
    return this.http.get(url.baseUrl + 'products?code=' + url.key);
  }

  getProduct(product: Product): Observable<any> {
    return this.http.get(url.baseUrl + 'products/getProduct?_id=' + product._id + '&code=' + url.key);
  }

  updateProduct(product: Product): Observable<any> {
    return this.http.put(url.baseUrl + 'products?code=' + url.key, product);
  }

  deleteProduct(product: Product): Observable<any> {
    return this.http.delete(url.baseUrl + 'products/deleteProduct?productId=' + product._id + '&code=' + url.key);
  }
}
