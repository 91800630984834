import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalPopupPage } from './pages/sales/modal-popup/modal-popup.page';
import { SellerModelPage } from './pages/sellers/tabs/seller-model/seller-model.page';
import { CustomerModelPage } from './pages/customer/tabs/customer-model/customer-model.page';
import { AuthGuard } from './providers/auth-guard.service';
import { Authentication } from './providers/authenticating.service';
import { ProductModalPage } from './pages/product/tabs/product-modal/product-modal.page';
// import { FileOpener } from '@ionic-native/file-opener';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [AppComponent, ModalPopupPage, SellerModelPage, CustomerModelPage, ProductModalPage],
  providers: [
    InAppBrowser,
    SplashScreen,
    StatusBar,
    AuthGuard,
    Authentication,
    // FileOpener
  ],
  bootstrap: [AppComponent],
  entryComponents: [ModalPopupPage, SellerModelPage, CustomerModelPage, ProductModalPage]
})
export class AppModule { }
