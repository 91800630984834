import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as url from '../constants/url';
import { Sellar } from '../interfaces/sellar';


@Injectable({
  providedIn: 'root'
})
export class SellarService {

  constructor(
    public storage: Storage,
    private http: HttpClient
  ) { }

  addSellar(sellar: Sellar): Observable<any> {
    return this.http.post(url.baseUrl + 'sellar?code=' + url.key, sellar);
  }

  getAllSellar(): Observable<any> {
    return this.http.get(url.baseUrl + 'sellar?code=' + url.key);
  }

  getSellar(sellar: Sellar): Observable<any> {
    return this.http.get(url.baseUrl + 'sellar/getSellar?id=' + sellar._id + '&code=' + url.key);
  }

  getSellarsByAgent(sellar: Sellar): Observable<any> {
    return this.http.get(url.baseUrl + 'sellar/getSellarsByAgent?agent=' + sellar.agent + '&code=' + url.key);
  }

  updateSellar(sellar: Sellar): Observable<any> {
    return this.http.put(url.baseUrl + 'sellar?code=' + url.key, sellar);
  }

  deleteSellar(sellar: Sellar): Observable<any> {
    return this.http.delete(url.baseUrl + 'sellar/deleteSellar?id=' + sellar._id + '&code=' + url.key);
  }
}
