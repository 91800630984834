import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Authentication } from './authenticating.service';
import { UserData } from './user-data';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public authenticationService: Authentication,
    private userData: UserData
      ) {}

  canActivate(): boolean {
    return this.authenticationService.isAuthenticated();
  }

}
