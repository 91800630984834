export const idealForAry = [
  { name: 'Men', value: 'Men' },
  { name: 'Women', value: 'Women' },
  { name: 'Boy', value: 'Boy' },
  { name: 'Girl', value: 'Girl' },
  { name: 'Boy baby', value: 'Boy baby' },
  { name: 'Girl baby', value: 'Girl baby' }
];
export const sizeAry = [
  { name: 'No size', value: '' },
  { name: 'Free size', value: 'Free size' },
  { name: 'S', value: 'S' },
  { name: 'M', value: 'M' },
  { name: 'L', value: 'L' },
  { name: 'XL', value: 'XL' },
  { name: 'XXL', value: 'XXL' },
  { name: 'XXXL', value: 'XXXL' },
  { name: '2 mts', value: '2 mts' },
  { name: '3 mts', value: '3 mts' },
  { name: '4 mts', value: '4 mts' },
  { name: '5 mts', value: '5 mts' },
  { name: '5.5 mts', value: '5.5 mts' },
  { name: '6 mts', value: '6 mts' },
  { name: '6.5 mts', value: '6.5 mts' },
  { name: '0', value: '0' },
  { name: '16', value: '16' },
  { name: '18', value: '18' },
  { name: '20', value: '20' },
  { name: '22', value: '22' },
  { name: '24', value: '24' },
  { name: '26', value: '26' },
  { name: '28', value: '28' },
  { name: '30', value: '30' },
  { name: '32', value: '32' },
  { name: '34', value: '34' },
  { name: '36', value: '36' },
  { name: '38', value: '38' },
  { name: '40', value: '40' },
  { name: '42', value: '42' },
  { name: '44', value: '44' },
  { name: '46', value: '46' },
  { name: '48', value: '48' },
  { name: '50', value: '50' },
  { name: '45cm', value: '45cm' },
  { name: '50cm', value: '50cm' },
  { name: '55cm', value: '55cm' },
  { name: '60cm', value: '60cm' },
  { name: '65cm', value: '65cm' },
  { name: '70cm', value: '70cm' },
  { name: '75cm', value: '75cm' },
  { name: '80cm', value: '80cm' },
  { name: '85cm', value: '85cm' },
  { name: '90cm', value: '90cm' },
  { name: '95cm', value: '95cm' },
  { name: '100cm', value: '100cm' },
  { name: '105cm', value: '105cm' },
  { name: '110cm', value: '110cm' }
];
export const colorAry = [
  { name: 'Black', value: 'Black', color: '#000000' },
  { name: 'Brown', value: 'Brown', color: '#a52a2a' },
  { name: 'chocolate', value: 'chocolate', color: '#d2691e' },
  { name: 'Mint Cream', value: 'Mint Cream', color: '#f5fffa' },
  { name: 'Orange', value: 'Orange', color: '#ffa500' },
  { name: 'Orange Red', value: 'Orange Red', color: '#ff4500' },
  { name: 'Dark Orange', value: 'Dark Orange', color: '#ff8c00' },
  { name: 'Kavi(Deep Saffron)', value: 'Kavi(Deep Saffron)', color: '#ff9933' },
  { name: 'Maroon', value: 'Maroon', color: '#800000' },
  { name: 'Red', value: 'Red', color: '#ff0000' },
  { name: 'Dark Red', value: 'Red', color: '#8b0000' },
  { name: 'Indian Red', value: 'Indian Red', color: '#cd5c5c' },
  { name: 'Saffron', value: 'Saffron', color: '#f4c430' },
  { name: 'Sandal', value: 'Sandal', color: '#A89166' },
  { name: 'Silver', value: 'Silver', color: '#c0c0c0' },
  { name: 'Sky Blue', value: 'Sky Blue', color: '#87ceeb' },
  { name: 'Deep Sky Blue', value: 'Deep Sky Blue', color: '#00bfff' },
  { name: 'Light Sky Blue', value: 'Light Sky Blue', color: '#87cefa' },
  { name: 'Blue', value: 'Blue', color: '#0000ff' },
  { name: 'Navy Blue', value: 'Navy Blue', color: '#000080' },
  { name: 'Violet', value: 'Violet', color: '#ee82ee' },
  { name: 'Blue Violet', value: 'Blue Violet', color: '#8a2be2' },
  { name: 'Dark Violet', value: 'Dark Violet', color: '#9400d3' },
  { name: 'Medium Violet', value: 'Medium Violet', color: '#c71585' },
  { name: 'Medium Violet Red', value: 'Medium Violet Red', color: '#c71585' },
  { name: 'Pale Violet', value: 'Pale Violet', color: '#db7093' },
  { name: 'Purple', value: 'Purple', color: '#800080' },
  { name: 'Light Pink', value: 'Light Pink', color: '#ffb6c1' },
  { name: 'Pink', value: 'Pink', color: '#ffc0cb' },
  { name: 'Deep Pink', value: 'Deep Pink', color: '#ff1493' },
  { name: 'Hot Pink', value: 'Hot Pink', color: '#ff69b4' },
  { name: 'Light Yellow', value: 'Light Yellow', color: '#fafac4' },
  { name: 'kakhi', value: 'kakhi', color: '#f0e68c' },
  { name: 'Yellow', value: 'Yellow', color: '#ffff00' },
  { name: 'Light Golden Rod Yellow', value: 'Light Golden Rod Yellow', color: '#fafad2' },
  { name: 'Gold', value: 'Gold', color: '#ffd700' },
  { name: 'Yellow Green', value: 'Yellow Green', color: '#9acd32' },
  { name: 'Green Yellow', value: 'Green Yellow', color: '#adff2f' },
  { name: 'Light Green', value: 'Light Green', color: '#90ee90' },
  { name: 'Green', value: 'Green', color: '#008000' },
  { name: 'Dark Green', value: 'Dark Green', color: '#224341' },
  { name: 'Jean Greenish Blue', value: 'Jean Greenish Blue', color: '#556b2f' },
  { name: 'Jean Blue Dark', value: 'Jean Blue Dark', color: '#1f315b' },
  { name: 'Jean Blue', value: 'Jean Blue', color: '#5681ad' },
  { name: 'Dark Olive Green', value: 'Dark Olive Green', color: '#556b2f' },
  { name: 'Light Sea Green', value: 'Light Sea Green', color: '#20b2aa' },
  { name: 'Sea Green', value: 'Sea Green', color: '#2e8b57' },
  { name: 'Medium Sea Green', value: 'Medium Sea Green', color: '#3cb371' },
  { name: 'Dark Sea Green', value: 'Dark Sea Green', color: '#8fbc8f' },
  { name: 'Forest Green', value: 'Forest Green', color: '#228b22' },
  { name: 'Lawn Green', value: 'Lawn Green', color: '#7cfc00' },
  { name: 'Lime Green', value: 'Lime Green', color: '#32cd32' },
  { name: 'Pale Green', value: 'Pale Green', color: '#98fb98' },
  { name: 'Spring Green', value: 'Spring Green', color: '#00ff7f' },
  { name: 'Light Grey', value: 'Light Grey', color: '#d3d3d3' },
  { name: 'Grey', value: 'Grey', color: '#808080' },
  { name: 'Dark Grey', value: 'Dark Grey', color: '#a9a9a9' },
  { name: 'Light Late Grey', value: 'Light Late Grey', color: '#778899' },
  { name: 'Dark Late Grey', value: 'Dark Late Grey', color: '#2f4f4f' },
  { name: 'Dim Grey', value: 'Dim Grey', color: '#696969' },
  { name: 'Corn Silk', value: 'Corn Silk', color: '#fff8dc' },
  { name: 'White', value: 'White', color: '#ffffff' },
];
export const types = [
  { name: 'Shirt', value: 'Shirt' },
  { name: 'Shirt Material', value: 'Shirt Material' },
  { name: 'T-shirt', value: 'T-shirt' },
  { name: 'T-shirt Roundneck', value: 'T-shirt Roundneck' },
  { name: 'T-shirt V-neck', value: 'T-shirt V-neck' },
  { name: 'T-shirt Collared', value: 'T-shirt Collared' },
  { name: 'Nehru Jackets', value: 'Nehru Jackets' },
  { name: 'Jackets', value: 'Jackets' },
  { name: 'Blazers', value: 'Blazers' },
  { name: 'Kurta Suit', value: 'Kurta Suit' },
  { name: 'Pathani Suit', value: 'Pathani Suit' },
  { name: 'Sherwani', value: 'Sherwani' },
  { name: 'Coat Suits', value: 'Coat Suits' },
  { name: 'Pant', value: 'Pant' },
  { name: 'Pant Material', value: 'Pant Material' },
  { name: 'Saree', value: 'Saree' },
  { name: 'Chudidhar', value: 'Chudidhar' },
  { name: 'Chudidhar material', value: 'Chudidhar material' },
  { name: 'Leggings', value: 'Leggings' },
  { name: 'Womens Top', value: 'Womens Top' },
  { name: 'Girls Top', value: 'Girls Top' },
  { name: 'Half saree', value: 'Half saree' },
  { name: 'Frog', value: 'Frog' },
  { name: 'Midi Dress', value: 'Midi Dress' },
  { name: 'Off the Shoulder', value: 'Off the Shoulder' },
  { name: 'Shift Dress', value: 'Shift Dress' },
  { name: 'Bodycon Dress', value: 'Bodycon Dress' },
  { name: 'A-Line Dress', value: 'A-Line Dress' },
  { name: 'Mini Dress', value: 'Mini Dress' },
  { name: 'Maxi Dress', value: 'Maxi Dress' },
  { name: 'Wrap Dress', value: 'Wrap Dress' },
  { name: 'Halter Dress', value: 'Halter Dress' },
  { name: 'High-Low Dress', value: 'High-Low Dress' },
  { name: 'Sheath Dress', value: 'Sheath Dress' },
  { name: 'Peplum Dress', value: 'Peplum Dress' },
  { name: 'Sweater Dress', value: 'Sweater Dress' },
  { name: 'Pencil Dress', value: 'Pencil Dress' },
  { name: 'Smock Dress', value: 'Smock Dress' },
  { name: 'Ballgown', value: 'Ballgown' },
  { name: 'Mermaid Silhouette Dress', value: 'Mermaid Silhouette Dress' },
  { name: 'Tea Length Dress', value: 'Tea Length Dress' },
  { name: 'Cocktail Dress', value: 'Cocktail Dress' },
  { name: 'Pinafore Dress', value: 'Pinafore Dress' },
  { name: 'Lehenga', value: 'Lehenga' },
  { name: 'Choli', value: 'Choli' },
  { name: 'Ghagra', value: 'Ghagra' },
  { name: 'Dupatta', value: 'Dupatta' },
  { name: 'Shalwar Kameez', value: 'Shalwar Kameez' },
  { name: 'Anarkali', value: 'Anarkali' },
  { name: 'Mekhela Chadar', value: 'Mekhela Chadar' },
  { name: 'Phiran', value: 'Phiran' },
  { name: 'One Piece', value: 'One Piece' },
  { name: 'Phulkari and Damaan', value: 'Phulkari and Damaan' },
  { name: 'Puanchei', value: 'Puanchei' },
  { name: 'Kurta', value: 'Kurta' },
  { name: 'Patiala', value: 'Patiala' },
  { name: 'Towels', value: 'Towels' },
  { name: 'Vest RN', value: 'Vest RN' },
  { name: 'Vest RNS', value: 'Vest RNS' },
  { name: 'Trunks', value: 'Trunks' },
  { name: 'Brief(WP)', value: 'Brief(WP)' },
  { name: 'Brief(WOP)', value: 'Brief(WOP)' },
  { name: 'Panties', value: 'Panties' },
  { name: 'Bra', value: 'Bra' },
  { name: 'Kercheif', value: 'Kercheif' },
  { name: 'Blouse', value: 'Blouse' },
  { name: 'Skirt', value: 'Skirt' },
  { name: 'Nighty', value: 'Nighty' },
  { name: 'Night Dress', value: 'Night Dress' },
  { name: 'Petticoat', value: 'Petticoat' },
  { name: 'InSkirt', value: 'InSkirt' },
  { name: 'Sweatshirt', value: 'Sweatshirt' },
  { name: 'Lungi', value: 'Lungi' },
  { name: 'Dhoti', value: 'Dhoti' },
  { name: 'Phyjama', value: 'Phyjama' },
  { name: 'Track pants', value: 'Track pants' },
  { name: 'Boxers', value: 'Boxers' },
  { name: 'Night suit', value: 'Night suit' },
  { name: 'Socks', value: 'Socks' },
  { name: 'Sleppers', value: 'Sleppers' },
  { name: 'Sandals', value: 'Sandals' },
  { name: 'Boots', value: 'Boots' },
  { name: 'Loafers', value: 'Loafers' },
  { name: 'Shoes', value: 'Shoes' },
  { name: 'Gowns', value: 'Gowns' },
  { name: 'Cargos', value: 'Cargos' },
  { name: 'Shararas', value: 'Shararas' },
  { name: 'Jeggins', value: 'Jeggins' },
  { name: 'Blouse', value: 'Blouse' },
  { name: 'Shorts', value: 'Shorts' },
  { name: 'Lingerie', value: 'Lingerie' }
];
export const fabrics = [
  { name: 'Canvas', value: 'Canvas' },
  { name: 'Cashmere', value: 'Cashmere' },
  { name: 'Chenille', value: 'Chenille' },
  { name: 'Chiffon', value: 'Chiffon' },
  { name: 'Cotton', value: 'Cotton' },
  { name: 'Crêpe', value: 'Crêpe' },
  { name: 'Damask', value: 'Damask' },
  { name: 'Denim', value: 'Denim' },
  { name: 'Jean', value: 'Jean' },
  { name: 'Cotton-Jean', value: 'Cotton-Jean' },
  { name: 'Georgette', value: 'Georgette' },
  { name: 'Gingham', value: 'Gingham' },
  { name: 'Jersey', value: 'Jersey' },
  { name: 'Lace', value: 'Lace' },
  { name: 'Leather', value: 'Leather' },
  { name: 'Linen', value: 'Linen' },
  { name: 'Merino Wool', value: 'Merino Wool' },
  { name: 'Modal', value: 'Modal' },
  { name: 'Muslin', value: 'Muslin' },
  { name: 'Organza', value: 'Organza' },
  { name: 'Polyester', value: 'Polyester' },
  { name: 'Rennial', value: 'Rennial' },
  { name: 'Satin', value: 'Satin' },
  { name: 'Silk', value: 'Silk' },
  { name: 'Spandex', value: 'Spandex' },
  { name: 'Synthetic', value: 'Synthetic' },
  { name: 'Suede', value: 'Suede' },
  { name: 'Taffeta', value: 'Taffeta' },
  { name: 'Toile', value: 'Toile' },
  { name: 'Tweed', value: 'Tweed' },
  { name: 'Twill', value: 'Twill' },
  { name: 'Velvet', value: 'Velvet' },
  { name: 'Viscose', value: 'Viscose' },
];
export const stateList = [
  { name: 'JAMMU AND KASHMIR', code: 1 },
  { name: 'HIMACHAL PRADESH', code: 2 },
  { name: 'PUNJAB', code: 3 },
  { name: 'CHANDIGARH', code: 4 },
  { name: 'UTTARAKHAND', code: 5 },
  { name: 'HARYANA', code: 6 },
  { name: 'DELHI', code: 7 },
  { name: 'RAJASTHAN', code: 8 },
  { name: 'UTTAR PRADESH', code: 9 },
  { name: 'BIHAR', code: 10 },
  { name: 'SIKKIM', code: 11 },
  { name: 'ARUNACHAL PRADESH', code: 12 },
  { name: 'NAGALAND', code: 13 },
  { name: 'MANIPUR', code: 14 },
  { name: 'MIZORAM', code: 15 },
  { name: 'TRIPURA', code: 16 },
  { name: 'MEGHLAYA', code: 17 },
  { name: 'ASSAM', code: 18 },
  { name: 'WEST BENGAL', code: 19 },
  { name: 'JHARKHAND', code: 20 },
  { name: 'ODISHA', code: 21 },
  { name: 'CHATTISGARH', code: 22 },
  { name: 'MADHYA PRADESH', code: 23 },
  { name: 'GUJARAT', code: 24 },
  { name: 'DADRA AND NAGAR HAVELI AND DAMAN AND DIU (NEWLY MERGED UT)', code: 26 },
  { name: 'MAHARASHTRA', code: 27 },
  { name: 'ANDHRA PRADESH(BEFORE DIVISION)', code: 28 },
  { name: 'KARNATAKA', code: 29 },
  { name: 'GOA', code: 30 },
  { name: 'LAKSHWADEEP', code: 31 },
  { name: 'KERALA', code: 32 },
  { name: 'TAMIL NADU', code: 33 },
  { name: 'PUDUCHERRY', code: 34 },
  { name: 'ANDAMAN AND NICOBAR ISLANDS', code: 35 },
  { name: 'TELANGANA', code: 36 },
  { name: 'ANDHRA PRADESH (NEWLY ADDED)', code: 37 },
  { name: 'LADAKH (NEWLY ADDED)', code: 38 }
];
