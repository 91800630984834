import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as url from '../constants/url';
import { User } from '../interfaces/user';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    public storage: Storage,
    private http: HttpClient
  ) { }

  addUser(user: User): Observable<any> {
    return this.http.post(url.baseUrl + 'users?code=' + url.key, user);
  }

  getAllUsers(): Observable<any> {
    return this.http.get(url.baseUrl + 'users?code=' + url.key);
  }

  getUser(user: User): Observable<any> {
    return this.http.get(url.baseUrl + 'users/getUser?uid=' + user.uid + '&code=' + url.key);
  }

  getUserById(uid: string): Observable<any> {
    return this.http.get(url.baseUrl + 'users/getUser?uid=' + uid + '&code=' + url.key);
  }

  updateUser(user: User): Observable<any> {
    return this.http.put(url.baseUrl + 'users?' + 'code=' + url.key, user);
  }

  deleteUser(user: User): Observable<any> {
    return this.http.delete(url.baseUrl + 'users/deleteUser?userId=' + user._id + '&code=' + url.key);
  }
}
