import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { NavParams, ModalController, ToastController } from '@ionic/angular';
import { SellarService } from '../../../../providers/sellar.service';
import { Accounts } from '../../../../interfaces/Accounts';
import { Sellar } from '../../../../interfaces/sellar';
import * as constants from '../../../../constants/constant';
import { Plugins } from '@capacitor/core';
import { BankDetails } from '../../../../interfaces/bankDetails';
const { Clipboard } = Plugins;

@Component({
  selector: 'app-seller-model',
  templateUrl: './seller-model.page.html',
  styleUrls: ['./seller-model.page.scss'],
})
export class SellerModelPage implements OnInit {
  @Input() seller: Sellar;
  isEditable = false;
  isGstValid = true;
  stateList = constants.stateList;
  accountForm: FormGroup;

  constructor(
    public navParams: NavParams,
    private service: SellarService,
    public modalCtrl: ModalController,
    public toastController: ToastController,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.seller = this.navParams.get('seller');
    this.accountForm = this.fb.group({
      accounts: this.fb.array([])
    });
    if (this.seller.bankDetails === null) {
      const bd: BankDetails = {
        accNo: null,
        ifsc: null,
        address: null,
        name: null
      };
      this.seller.bankDetails = bd;
    }
    this.seller.accounts.forEach(acnt => {
      const group = new FormGroup({
        date: this.fb.control(acnt.date),
        credit: this.fb.control(acnt.credit),
        paid: this.fb.control(acnt.paid),
        billNo: this.fb.control(acnt.billNo)
       });
      this.accounts.push(group);
    });
    // console.log(this.accounts.value());
  }

  get accounts(): FormArray {
    return this.accountForm.get('accounts') as FormArray;
  }

  addAccounts() {
    this.accounts.push(this.newAccount());
  }

  deleteAccounts(indx: number) {
    this.accounts.removeAt(indx);
  }

  newAccount(): FormGroup {
    return this.fb.group({
      date: '', credit: 0, paid: 0, billNo: ''
    });
  }

  getTotal(accounts: Accounts[]) {
    let balance = 0;
    let credit = 0;
    let paid = 0;
    if (accounts !== null && accounts.length !== 0) {
      accounts.forEach(act => {
        credit += act.credit;
        paid += act.paid;
      });
      balance = credit - paid;
    }
    return balance;
  }

  toggleEditing() {
    this.isEditable = !this.isEditable;
  }

  updateSeller() {
    this.seller.accounts = this.accountForm.value.accounts;
    // console.log(this.seller);
    this.service.updateSellar(this.seller).subscribe(res => {
      this.presentToast('Updated successfully');
      this.toggleEditing();
    }, err => {
      this.presentToast('Err occured try again...');
    });
  }

  afterEnteringGSt(event) {
    this.isGstValid = true;
    this.seller.gstNo = this.seller.gstNo.toUpperCase();
    if (this.seller.gstNo.length === 15) {
      this.seller.panNo = this.seller.gstNo.substring(2, 12);
      const tin = this.seller.gstNo.substring(0, 2);
      const indx = this.stateList.findIndex(x => x.code === Number(tin));
      if (indx === -1) {
        this.isGstValid = false;
      } else {
        this.seller.state = this.stateList[indx].name;
      }
      if (this.seller.gstNo.charAt(13) !== 'Z') {
        this.isGstValid = false;
      }
    } else {
      this.isGstValid = false;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  copy(text: string) {
    try {
      Clipboard.write({
        string: text
      });
      this.presentToast('Copied !!!');
    } catch (error) {
      this.presentToast('not copied, try again:(');
    }
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

}
