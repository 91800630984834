import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { UserOptions } from '../interfaces/user-options';

@Injectable({
  providedIn: 'root'
})
export class Authentication {
  authState = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private storage: Storage,
    private platform: Platform,
    public toastController: ToastController
  ) {
    this.platform.ready().then(() => {
      this.ifLoggedIn();
    });
  }

  ifLoggedIn() {
    this.storage.get('USER_INFO').then((response) => {
      if (response) {
        this.authState.next(true);
      }
    });
  }


  login(credentials: UserOptions) {
    if (credentials.username === 'sas' && credentials.password === 'Amman64sek') {

      const dummyResponse = {
        user_id: 'sas',
        user_name: 'Amman64sek'
      };
      this.storage.set('USER_INFO', dummyResponse).then((response) => {
        this.router.navigate(['home']);
        this.authState.next(true);
      });
      // return window.dispatchEvent(new CustomEvent('user:login'));
    } else {
      return 'failed';
    }
  }

  logout() {
    this.storage.remove('USER_INFO').then(() => {
      this.router.navigate(['login']);
      this.authState.next(false);
    });
    // .then(() => {
    //   window.dispatchEvent(new CustomEvent('user:logout'));
    // });
  }

  isAuthenticated() {
    return this.authState.value;
  }
}
