import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.page.html',
  styleUrls: ['./modal-popup.page.scss'],
})
export class ModalPopupPage implements OnInit {
  @Input() title: string;
  @Input() images: Array<any>;
  src: string;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController
  ) {}

  ngOnInit(): void {
    this.title = this.navParams.get('title');
    this.images = this.navParams.get('images');
    this.src = this.images[0];
  }

  selectImg(indx) {
    this.src = this.images[indx];
  }

  closeModal() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

}
