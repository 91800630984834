import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Product } from '../../../../interfaces/product';
import { ProductService } from '../../../../providers/product.service';
import * as constant from '../../../../constants/constant';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.page.html',
  styleUrls: ['./product-modal.page.scss'],
})
export class ProductModalPage implements OnInit {
  @Input() product: Product;
  @Input() edit: boolean;
  selectedImg: any;
  isUploading = false;
  size: Array<any> = [];
  idealFor: any;
  type: any;
  color: Array<any> = [];
  fabric: any;
  idealAry = constant.idealForAry;
  colorAry = constant.colorAry;
  sizeAry = constant.sizeAry;
  fabricAry = constant.fabrics;
  typeAry = constant.types;

  constructor(
    private service: ProductService,
    public modalCtrl: ModalController,
    public toastController: ToastController) { }

  ngOnInit() {
    this.selectedImg = this.product.image.length > 0 ? this.product.image[0] : null;
    const ideal = this.idealAry.find(x => x.name === this.product.idealFor.name);
    this.idealFor = ideal !== undefined && ideal !== null ? ideal.name : undefined;
    const fab = this.fabricAry.find(x => x.name === this.product.fabric.name);
    this.fabric = fab !== undefined && fab !== null ? fab.name : undefined;
    const type = this.typeAry.find(x => x.name === this.product.type.name);
    this.type = type !== undefined && type !== null ? type.name : undefined;
    this.product.color.forEach(color => {
      this.color.push(color.name);
    });
    this.product.size.forEach(size => {
      this.size.push(size.name);
    });
  }

  onSelectImg(imgSrc) {
    this.selectedImg = imgSrc;
  }

  editProduct() {
    this.isUploading = true;
    this.product.idealFor = this.idealAry.find(x => x.name === this.idealFor);
    this.product.fabric = this.fabricAry.find(x => x.name === this.fabric);
    this.product.type = this.typeAry.find(x => x.name === this.type);
    this.product.color = [];
    this.color.forEach(color => {
      this.product.color.push(this.colorAry.find(x => x.name === color));
    });
    this.product.size = [];
    this.size.forEach(size => {
      this.product.size.push(this.sizeAry.find(x => x.name === size));
    });
    this.service.updateProduct(this.product).subscribe(res => {
      this.presentToast('Product added successfully!!!');
      this.edit = false;
      this.isUploading = false;
    }, err => {
      this.presentToast('Not added, Try again...');
      this.isUploading = false;
    });
  }

  onSelectingImg(event) {
    const files = event.target.files;
    if (files.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = e => {
          this.product.image.push(reader.result);
        };
        reader.readAsDataURL(files[i]);
      }
    }
  }

  clearImages() {
    this.product.image = [];
  }

  closeModal() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

}
