import { Component, Input, OnInit } from '@angular/core';
import { NavParams, ModalController, ToastController } from '@ionic/angular';
import { User } from '../../../../interfaces/user';
import { Plugins } from '@capacitor/core';
const { Clipboard } = Plugins;
import * as constants from '../../../../constants/constant';
import { CustomerService } from '../../../../providers/customer.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-customer-model',
  templateUrl: './customer-model.page.html',
  styleUrls: ['./customer-model.page.scss'],
})
export class CustomerModelPage implements OnInit {
@Input() user: User;
isEditable = false;
accountForm: FormGroup;
isLoading = false;

  constructor(
    public navParams: NavParams,
    private service: CustomerService,
    public modalCtrl: ModalController,
    public toastController: ToastController,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.user = this.navParams.get('user');
    this.accountForm = this.fb.group({
      accounts: this.fb.array([])
    });
    if (this.user.accounts !== null && this.user.accounts !== undefined) {
      this.user.accounts.forEach(acnt => {
        const group = new FormGroup({
          date: this.fb.control(acnt.date),
          credit: this.fb.control(acnt.credit),
          paid: this.fb.control(acnt.paid),
          billNo: this.fb.control(acnt.billNo)
         });
        this.accounts.push(group);
      });
    }
  }

  get accounts(): FormArray {
    return this.accountForm.get('accounts') as FormArray;
  }

  addAccounts() {
    this.accounts.push(this.newAccount());
  }

  deleteAccounts(indx: number) {
    this.accounts.removeAt(indx);
  }

  newAccount(): FormGroup {
    return this.fb.group({
      date: '', credit: 0, paid: 0, billNo: ''
    });
  }

  toggleEditing() {
    this.isEditable = !this.isEditable;
  }

  updateUser() {
    this.isLoading = true;
    this.user.accounts = this.accountForm.value.accounts;
    this.service.updateUser(this.user).subscribe(res => {
      this.presentToast('Updated successfully');
      this.toggleEditing();
      this.isLoading = false;
    }, err => {
      this.presentToast('Err occured try again...');
      this.isLoading = false;
    });
  }

  closeModal() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  copy(text: string) {
    try {
      Clipboard.write({
        string: text
      });
      this.presentToast('Copied !!!');
    } catch (error) {
      this.presentToast('not copied, try again:(');
    }
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

}
